import { TermsContent } from "./TermsContent";

export default function TermsSection() {
  return (
    <>
      <h2>Terms</h2>
      <TermsContent />
    </>
  );
}

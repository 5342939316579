// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCEsb8BLbmH-tyI79fKxvx9irl8VVWgVKU",
  authDomain: "dk-job.firebaseapp.com",
  projectId: "dk-job",
  storageBucket: "dk-job.appspot.com",
  messagingSenderId: "1039321629261",
  appId: "1:1039321629261:web:e8b67df5b009d517045204",
  measurementId: "G-ED7TSVRR8D",
};
